import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../utils/Element";
import badge from "../../assets/icons/badge.svg";
import BaseLayout from "../../layouts/BaseLayout";
import dollar from "../../assets/icons/dollar 2.svg";
import key from "../../assets/icons/key-variant.svg";
import calendar from "../../assets/icons/calendar.svg";
import LeftIcon from "../../assets/svgs/leftArrowIcon.svg";
import percentage from "../../assets/icons/percentage 2.svg";
import RightIcon from "../../assets/svgs/rightArrowIcon.svg";
import BuyersDetailsHeader from "./components/BuyersDetailsHeader";
import SearchDropdownList from "../../components/SearchDropdownList";

const BuyersDetails = () => {
  const { id } = useParams();
  const [config] = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [buyerData, setBuyerData] = useState();

  const fetchDetails = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/acquisition-getbyid/${id}`, config)
      .then((res) => {
        const value = res?.data?.data;
        setBuyerData(value);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDetails();
  }, [id]);

  const leaseData = [
    { icon: key, date: buyerData?.min_lease_term_reamaining ? `${buyerData?.min_lease_term_reamaining} ${buyerData?.min_lease_term_reamaining > 1 ? "Years" : "Year"} ` : "0 Year", title: "Min Lease Term Remaining" },
    { icon: dollar, date: buyerData?.min_price ? `$ ${buyerData?.min_price?.toLocaleString()}` : "N/A", title: "Min Price" },
    { icon: dollar, date: buyerData?.max_price ? `$ ${buyerData?.max_price?.toLocaleString()}` : "N/A", title: "Max Price" },
    { icon: percentage, date: buyerData?.min_asking_cap_rate ? `${buyerData?.min_asking_cap_rate} %` : "N/A", title: "Asking Cap Rate" },
  ];

  const queryParams = new URLSearchParams(location.search);
  const searchValue = queryParams.get('search');

  return (
    <BaseLayout>
      <div className="flex justify-between items-center">
        <p role="button" className="green-H head-5 underline" onClick={() => navigate(`/buyers?search=${searchValue || ''}`)}>
          Back to Buyers                                       
        </p>

        <div className="body-S dark-M flex items-center gap-5">
          {buyerData?.previous_id !== null && (
            <div role="button" onClick={() => navigate(`/buyer/${buyerData?.previous_id}`)} className="flex items-center gap-2">
              <img src={LeftIcon} alt="" />
              Previous Buyer
            </div>
          )}

          {buyerData?.next_id !== null && (
            <div role="button" onClick={() => navigate(`/buyer/${buyerData?.next_id}`)} className="flex items-center gap-2">
              Next Buyer
              <img src={RightIcon} alt="" />
            </div>
          )}
        </div>

        <SearchDropdownList from="buyer" />
      </div>

      <div className="contact-details light-bg-L">
        {loading ? (
          <Loader />
        ) : (
          <>
            <BuyersDetailsHeader buyerData={buyerData} onCallApi={fetchDetails} />

            <div className="mt-6">
              <p className="dark-M body-L body-N">SEARCH CRITERIA</p>
              <div className="md:flex flex-wrap justify-between gap-4 mt-2">
                {leaseData.flatMap((el, idx) => (
                  <div key={idx}>
                    <p className="dark-M body-N">{el.title}</p>
                    <p className="tags green-H body-S body-N">{el.date}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-6">
              <p className="dark-M body-N">Availability Status</p>
              <div className="flex flex-wrap items-center gap-2 mt-1">
                {buyerData?.availability_status?.map((el, i) => (
                  <p key={i} className="tags green-H body-S">
                    {el}
                  </p>
                ))}
              </div>
            </div>

            <div className="md:flex justify-between body-N">
              <div className="mt-6 md:w-[50%]">
                <p className="dark-M">Property Type</p>
                <div className="flex flex-wrap items-center gap-2 mt-1">
                  {buyerData?.property_type?.map((el, i) => (
                    <p key={i} className="tags green-H body-S">
                      {el?.type}
                    </p>
                  ))}
                </div>
              </div>

              <div className="mt-6 md:w-[50%]">
                <p className="dark-M">Tenants</p>
                <div className="flex flex-wrap items-center gap-2 mt-1">
                  {buyerData?.tenant_details?.map((el, i) => (
                    <p key={i} className="tags green-H body-S">
                      {el?.tenant_name}
                    </p>
                  ))}
                </div>
              </div>
            </div>

            <div className="md:flex justify-between body-N">
              <div className="mt-6 md:w-[50%]">
                <p className="dark-M">Landlord Responsibilities</p>
                <div className="flex flex-wrap items-center gap-2 mt-1">
                  {buyerData?.landlord_responsibilities?.map((el, i) => (
                    <p key={i} className="tags green-H body-S">
                      {el}
                    </p>
                  ))}
                </div>
              </div>

              <div className="mt-6 md:w-[50%]">
                <p className="dark-M">State</p>
                <div className="flex flex-wrap items-center gap-2 mt-1">
                  {buyerData?.state?.map((el, i) => (
                    <p key={i} className="tags green-H body-S">
                      {el}
                    </p>
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-6 body-N">
              <p className="dark-M">Comments</p>
              <p className="dark-H">{buyerData?.comment ?? "N/A"}</p>
            </div>
          </>
        )}
      </div>
    </BaseLayout>
  );
};

export default BuyersDetails;
