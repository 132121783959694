import React, { useState } from "react";
import Dropdown from "react-dropdown";
import Plus from "../../assets/svgs/Plus 2.svg";
import Close from "../../assets/svgs/Close 2.svg";
import { phoneOptions } from "../../utils/options";

const PhoneField = ({ phones, onSetPhones }) => {
  const [showAddMsg, setShowAddMsg] = useState(false);

  const handleAdd = (el) => {
    if (el.phone_number === "" || el.phone_category === "") {
      setShowAddMsg(true);
    } else {
      setShowAddMsg(false);
      onSetPhones([...phones, { phone_category: "", country_code: "+1", phone_number: "", ext: "" }]);
    }
  };

  const handleRemoveContent = (idx) => {
    onSetPhones(phones.map((l, i) => (i === idx ? { ...l, phone_number: "", ext: "" } : l)));
  };

  const handleRemoveField = (idx) => {
    const filterData = phones.filter((_, i) => idx !== i);
    onSetPhones(filterData);
  };

  const handleChange = (value, name, idx) => {
    setShowAddMsg(false);

    if (name === "phone_number") {
      const cleanedValue = value.replace(/\D/g, ""); 
      const formattedValue = formatPhoneNumber(cleanedValue); 

   
      onSetPhones(phones.map((l, i) => (i === idx ? { ...l, phone_number: formattedValue } : l)));
    } else {
      
      onSetPhones(phones.map((l, i) => (i === idx ? { ...l, [name]: value } : l)));
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const cleaned = phoneNumber.replace(/\D/g, ""); 
    const match = cleaned.match(/(\d{3})(\d{3})(\d{4})/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  return (
    <div className="mt-6">
      <label className="dark-H head-4 mb-2">
        Phone <span className="body-S dark-M">(First is always primary)</span>
      </label>

      {phones.length === 0 && (
        <div className="flex gap-2 items-center">
          <div className="flex flex-1 items-start">
            <div style={{ width: "30%" }}>
              <Dropdown
                className="phone-select body-N"
                options={phoneOptions}
                placeholder="Select"
                value={""} 
                onChange={(option) => handleChange(option.value, "phone_category", 0)}
              />
            </div>
            <input
              className="body-N"
              name="phone_number"
              type="text"
              placeholder="Add Phone Number"
              style={{ borderRadius: "0px" }}
              value={""} 
              onChange={(e) => handleChange(e.target.value, "phone_number", 0)}
            />
            <input
              className="body-N"
              name="ext"
              type="number"
              min="0"
              placeholder="ext."
              style={{ borderRadius: "0px", width: "30%" }}
              value={""} 
              onChange={(e) => handleChange(e.target.value, "ext", 0)}
              onWheel={(e) => e.target.blur()} 
            />
          </div>
          <button onClick={() => handleAdd({ phone_number: "", phone_category: "" })}>
            <img src={Plus} alt="plus" />
          </button>
        </div>
      )}

      {phones.map((el, idx) => (
        <div key={idx}>
          <div className="flex gap-2 items-center">
            <div className="flex flex-1 items-start">
              <div style={{ width: "30%" }}>
                <Dropdown
                  className="phone-select body-N"
                  options={phoneOptions}
                  placeholder="Select"
                  value={phoneOptions.find((data) => data.value === el.phone_category) || ""}
                  onChange={(option) => handleChange(option.value, "phone_category", idx)}
                />
              </div>

              <input
                className="body-N"
                name="phone_number"
                type="text"
                placeholder="Add Phone Number"
                style={{ borderRadius: "0px" }}
                value={el.phone_number}
                onChange={(e) => handleChange(e.target.value, "phone_number", idx)}
              />

              <input
                className="body-N"
                name="ext"
                type="number"
                min="0"
                placeholder="ext."
                style={{ borderRadius: "0px", width: "30%" }}
                value={el.ext}
                onChange={(e) => handleChange(e.target.value, "ext", idx)}
                onWheel={(e) => e.target.blur()} 
              />
            </div>

            <img
              src={Close}
              alt="close"
              role="button"
              className="sidebar-icons"
              onClick={() => (phones.length === 1 ? handleRemoveContent(idx) : handleRemoveField(idx))}
            />

            {idx === phones.length - 1 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAdd(el);
                }}
              >
                <img src={Plus} alt="plus" />
              </button>
            )}
          </div>
          {showAddMsg && <p className="red-D body-S">Please fill all fields first</p>}
        </div>
      ))}
    </div>
  );
};

export default PhoneField;
