import React, { useState } from "react";
import Dropdown from "react-dropdown";
import Plus from "../../assets/svgs/Plus 2.svg";
import { linkOptions } from "../../utils/options";
import Close from "../../assets/svgs/Close 2.svg";

const LinkField = ({ links, onSetLinks }) => {
  const [showAddMsg, setShowAddMsg] = useState(false);

  const handleAdd = () => {
   
    const lastLink = links[links.length - 1];
     if (lastLink.link === "" || lastLink.link_category === "") {
      setShowAddMsg(true);
    } else {
      setShowAddMsg(false);
      onSetLinks([...links, { link_category: "", link: "" }]);
    }
  };

  const handleRemove = (idx) => {
    if (links.length === 1) {
      onSetLinks([{ link_category: "", link: "" }]);
    } else {
      const updatedLinks = links.filter((_, i) => i !== idx);
      onSetLinks(updatedLinks);
    }
  };

  const handleChange = (value, name, idx) => {
    setShowAddMsg(false);
    onSetLinks(links.map((l, i) => (i === idx ? { ...l, [name]: value } : l)));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    const isValid = links.every((el) => el.link !== "" && el.link_category !== "");
    if (isValid) {
     
      console.log("Form submitted:", links);
      onSetLinks([{ link_category: "", link: "" }]); 
    } else {
      setShowAddMsg(true); 
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-6">
      <label className="dark-H head-4 mb-2">Links</label>

      {links.map((el, idx) => (
        <div key={idx} className="flex gap-2 items-center mb-2">
          <div className="flex flex-1 items-start">
            <div style={{ width: "30%" }}>
              <Dropdown
                className="phone-select body-N"
                options={linkOptions}
                placeholder="Select"
                value={linkOptions.find((data) => el.link_category === data.value)}
                onChange={(option) => handleChange(option.value, "link_category", idx)}
              />
            </div>

            <input
              className="body-N"
              name="link"
              type="text"
              placeholder="Add Links"
              style={{ borderRadius: "0px 8px 8px 0px" }}
              value={el.link}
              onChange={(e) => handleChange(e.target.value, "link", idx)}
            />
          </div>

         
          <div className="flex items-center">
            <button
              type="button" 
              onClick={(e) => {
                e.preventDefault(); 
                handleAdd(); 
              }}
              style={{ marginRight: "8px" }}
            >
              <img src={Plus} alt="plus" />
            </button>
            <button
              type="button" 
              onClick={(e) => {
                e.preventDefault(); 
                handleRemove(idx);
              }}
            >
              <img src={Close} alt="close" />
            </button>
          </div>
        
          {showAddMsg && idx === links.length - 1 && (
            <p className="red-D body-S">Please fill all fields first</p>
          )}
        </div>
      ))}
    
    </form>
  );
};

export default LinkField;
