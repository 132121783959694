import React, { useState, useEffect } from "react";
import Dropdown from "react-dropdown";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { BASE_URL } from "../../utils/Element";
import Plus from "../../assets/svgs/Plus 2.svg";
import Close from "../../assets/svgs/Close 2.svg";
import SearchDropdown from "../Dropdowns/SearchDropdown";
import { NotificationManager } from "react-notifications";
import { relationshipOptions } from "../../utils/options";

const RelationshipField = ({ relations = [], onSetRelations }) => {  
  const [config] = useAuth();
  const [showAddMsg, setShowAddMsg] = useState(false);
  const [contactOptionsArray, setContactOptionsArray] = useState([]);

  useEffect(() => {
    if (relations.length === 0) {
      onSetRelations([{ relation_category: "", name: "", id: "" }]);
    }
  }, [relations, onSetRelations]);

  const fetchContactSuggestions = (inputValue, idx) => {
    if (inputValue !== "" && inputValue !== relations[idx]?.name) { 
      axios
        .get(`${BASE_URL}/get-datalisting?list=contacts&search=${inputValue}`, config)
        .then((res) => {
          const value = res?.data?.data?.contact_list;
          const updatedOptions = value?.map((data) => ({
            value: data?.id,
            label: `${data?.first_name} ${data?.last_name}`,
          }));

          setContactOptionsArray((prev) => {
            const newOptions = [...prev];
            newOptions[idx] = updatedOptions;
            return newOptions;
          });
        })
        .catch((err) => {
          if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
            NotificationManager.error(err.response?.data?.message);
          }
        });
    } else {
      setContactOptionsArray((prev) => {
        const newOptions = [...prev];
        newOptions[idx] = [];
        return newOptions;
      });
    }
  };

  const handleAdd = () => {
    if (relations.some((rel) => !rel.id || !rel.relation_category)) {
      setShowAddMsg(true);
    } else {
      setShowAddMsg(false);
      onSetRelations([...relations, { relation_category: "", name: "", id: "" }]);
      setContactOptionsArray((prev) => [...prev, []]);
    }
  };

  const handleRemove = (idx) => {
    if (relations.length === 1) {
      onSetRelations([{ relation_category: "", name: "", id: "" }]);
      setContactOptionsArray([[]]);
    } else {
      const updatedRelations = relations.filter((_, i) => i !== idx);
      onSetRelations(updatedRelations);
      setContactOptionsArray((prev) => prev.filter((_, i) => i !== idx));
    }
  };

  const handleChange = (value, name, idx) => {
    setShowAddMsg(false);
    onSetRelations(relations.map((rel, i) => (i === idx ? { ...rel, [name]: value } : rel)));
  };

  const handleSelectContact = (option, idx) => {
    const isDuplicateContact = relations.some(
      (rel, i) => i !== idx && rel.id === option.value
    );
    
    if (isDuplicateContact) {
      NotificationManager.error("This contact is already added.");
      return;
    }

    onSetRelations(
      relations.map((rel, i) =>
        i === idx ? { ...rel, name: option.label, id: option.value } : rel
      )
    );

    setContactOptionsArray((prev) => {
      const newOptions = [...prev];
      newOptions[idx] = [];
      return newOptions;
    });
    setShowAddMsg(false);
  };

  return (
    <div className="mt-6">
      <label className="dark-H head-4 mb-2">Relationships</label>

      {relations.map((el, idx) => (
        <div key={idx}>
          <div className="flex gap-2 items-center">
            <div className="flex flex-1 items-start">
              <div style={{ width: "30%" }}>
                <Dropdown
                  className="phone-select body-N"
                  options={relationshipOptions}
                  placeholder="Select"
                  value={relationshipOptions.find((data) => data.value === el.relation_category)}
                  onChange={(option) => handleChange(option.value, "relation_category", idx)}
                />
              </div>

              <div className="mt-[5px]" style={{ width: "70%" }}>
                <SearchDropdown
                  isTop={false}
                  placeholder="Search Here..."
                  text={el.name}
                  options={contactOptionsArray[idx] || []}
                  fetchSuggestions={(inputValue) => fetchContactSuggestions(inputValue, idx)}
                  onSetOptions={(value) => {
                    setContactOptionsArray((prev) => {
                      const newOptions = [...prev];
                      newOptions[idx] = value;
                      return newOptions;
                    });
                  }}
                  handleSelect={(option) => handleSelectContact(option, idx)}
                />
              </div>
            </div>

            <button
              onClick={(e) => {
                e.preventDefault();
                handleAdd();
              }}
            >
              <img src={Plus} alt="add" />
            </button>

            <img
              src={Close}
              alt="close"
              role="button"
              onClick={() => handleRemove(idx)}
            />
          </div>
          {showAddMsg && <p className="red-D body-S">Please fill all fields first</p>}
        </div>
      ))}
    </div>
  );
};

export default RelationshipField;
