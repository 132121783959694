import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../utils/Element";
import gmail from "../../assets/images/gmail.png";
import BaseLayout from "../../layouts/BaseLayout";
import ConnectAppBox from "./components/ConnectAppBox";
import mailchimp from "../../assets/images/mailchimp.png";
import ringcentral from "../../assets/images/ringcentral.png";

const ConnectedApp = () => {
  const [config] = useAuth();
  const [loading, setLoading] = useState(false);
  const userType = useSelector((state) => state.userType);
  const [connectedAppData, setConnectedAppData] = useState({
    gmail: "",
    mailchimp: "",
    ringcentral: "",
  });

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/connectedapp-data`, config)
      .then((res) => {
        const data = res?.data?.data;
        setConnectedAppData({
          gmail: data?.gmail?.email ?? "",
          mailchimp: data?.mailchimp?.email ?? "",
          ringcentral: data?.ringcentral?.email ?? "",
        });
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleConnect = (type) => {
    const url = type === "gmail" ? "google/redirect" : type === "mailchimp" ? "auth/mailchimp" : "ringcentral/auth";

    axios.get(`${BASE_URL}/${url}`, config).then((res) => {
      if (res?.data) {
        const width = 800;
        const height = 600;
        const googleAuthUrl = type === "ringcentral" ? res?.data?.data : res?.data?.auth_url;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        const options = `width=${width},height=${height},left=${left},top=${top},modal=yes,centerscreen=yes`;

        const googleWindow = window.open(googleAuthUrl, "Google Authentication", options);

        window.addEventListener("message", (event) => {
          if (event.source === googleWindow) {
            const data = event.data;
            if (data.success) {
              fetchData();
              googleWindow.close();
            } else {
              googleWindow.close();
            }
          } else {
            fetchData();
            googleWindow.close();
          }
        });
      }
    });
  };

  const handleDisconnect = (type) => {
    axios
      .delete(`${BASE_URL}/disconnect-email?type=${type}`, config)
      .then((res) => {
        fetchData();
        if (res?.data?.message) {
          NotificationManager.success("Account Disconnected Successfully!");
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  return (
    <BaseLayout>
      <div className="flex justify-between items-center">
        <p className="head-1 dark-H">Connected App</p>
      </div>

      <div className="light-bg-L p-5 body-L dark-M table-container h-[78vh] overflow-y-auto">
        {loading ? (
          <Loader />
        ) : (
          <div>
            <ConnectAppBox
              image={gmail}
              title={"Gmail"}
              account={connectedAppData?.gmail}
              handleConnect={() => handleConnect("gmail")}
              handleDisconnect={() => {
                handleDisconnect("gmail");
              }}
              desc={"Connect Gmail to synchronize emails and manage communication directly within the dashboard."}
            />

            <hr className="my-4 dark-L" />

            <ConnectAppBox
              image={ringcentral}
              title={"RingCentral"}
              account={connectedAppData?.ringcentral}
              handleConnect={() => handleConnect("ringcentral")}
              handleDisconnect={() => {
                handleDisconnect("ringcentral");
              }}
              desc={"Integrate RingCentral for seamless call management and to track call logs and activities in one place."}
            />

            {userType === 1 && (
              <div>
                <hr className="my-4 dark-L" />

                <ConnectAppBox
                  image={mailchimp}
                  title={"Mailchimp"}
                  account={connectedAppData?.mailchimp}
                  handleConnect={() => handleConnect("mailchimp")}
                  handleDisconnect={() => {
                    handleDisconnect("mailchimp");
                  }}
                  desc={"Link Mailchimp to streamline email marketing campaigns and monitor their performance through the dashboard."}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

export default ConnectedApp;
