import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import phone from "../../../assets/svgs/call.svg";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import ActionsMenu from "../../../components/ActionsMenu";
import { formatPhoneNumber } from "../../../utils/utils";
import DeleteConfirmationModal from "../../../components/ConfirmationModals/DeleteConfirmationModal";

const PropertyDetailHeader = ({ propertydata }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const navigate = useNavigate();
  const [disableDelete, setDisableDelete] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = () => {
    setDisableDelete(true);
    axios
      .get(`${BASE_URL}/delete-property/${id}`, config)
      .then((res) => {
        if (res.data.success) {
          navigate("/properties");
        }
        setShowDeleteModal(false);
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setDisableDelete(false));
  };

  return (
    <div>
      <div className="md:flex items-start justify-between">
        <div>
          <p className="head-2 dark-H normal-case">{propertydata?.property_name ? propertydata?.property_name : "N/A"}</p>
          <p className="body-N dark-M capitalize">{propertydata?.property_type ? propertydata?.property_type?.type : "N/A"}</p>
        </div>

        <div className="mt-5 md:mt-0">
          <ActionsMenu
            handleEdit={() => {
              navigate(`/edit-property/${id}`);
            }}
            handleDelete={() => {
              setShowDeleteModal(true);
            }}
          />
        </div>
      </div>

      <div className="mt-6 md:flex justify-between body-N">
        <div>
          <p className="dark-M">Last Update Date</p>
          <p className="dark-H">{moment(propertydata?.updated_at).tz('America/New_York').format("MM/DD/YYYY")}</p>
        </div>

        <div className="mt-5 md:mt-0">
          <p className="dark-M">Store #</p>
          <p className="dark-H">{propertydata?.store ? propertydata?.store : "N/A"}</p>
        </div>

        <div className="mt-5 md:mt-0">
          <p className="dark-M">Address</p>
          <p className="dark-H">{propertydata?.street_address ? `${propertydata?.street_address}, ${propertydata?.city}, ${propertydata?.state}, ${propertydata?.zipcode}` : "N/A"}</p>
        </div>

        <div className="mt-5 md:mt-0">
          <div className="flex flex-col">
            <span className="dark-M">Google Maps Link</span>
            <p
              role={propertydata?.google_map_link ? "button" : ""}
              className="head-5 green-H underline"
              onClick={() => {
                if (propertydata?.google_map_link) {
                  window.open(propertydata?.google_map_link, "_blank");
                }
              }}
            >
              {propertydata?.google_map_link ? propertydata?.google_map_link?.substring(0, 25) : "N/A"}
            </p>
          </div>

          <div className="flex flex-col mt-1">
            <span className="dark-M">RPR Link</span>
            <p
              role={propertydata?.rpr_link ? "button" : ""}
              className="head-5 green-H border-2 Rpr-border"
              onClick={() => {
                if (propertydata?.rpr_link) {
                  window.open(propertydata?.rpr_link, "_blank");
                }
              }}
            >
              {propertydata?.rpr_link ? 'RPR' : "N/A"}
            </p>
          </div>
        </div>

        <div className="mt-5 md:mt-0">
        <p className="head-5 green-H underline">
           <Link
             href={
               propertydata?.owner_company_id
                 ? `/company/${propertydata?.owner_company_id}`
                 : `/contact/${propertydata?.owner_contact_id}`
             }
             rel="noopener noreferrer"
             onClick={(e) => {
               e.preventDefault(); 
               if (propertydata?.owner_company_id) {
                 navigate(`/company/${propertydata?.owner_company_id}`);
               } else {
                 navigate(`/contact/${propertydata?.owner_contact_id}`);
               }
             }}
             className="underline"
           >
             {propertydata?.owner?.name}
           </Link>
         </p>

          <div className="flex items-center gap-2 mt-1 ">
            <img src={phone} alt="icon" className="sidebar-icons" />
            {propertydata?.owner_phone?.length > 0 ? (
              <p className="head-5 dark-H">
                {propertydata?.owner_phone ? `${formatPhoneNumber(propertydata?.owner_phone?.[0]?.phone_number)}` : "N/A"} <span className="body-S dark-M">{propertydata?.owner_phone?.[0]?.ext ? `${propertydata?.owner_phone?.[0]?.ext}` : ""}</span> <span className="body-XS green-H tag-data">{propertydata?.owner_phone?.length > 0 ? `${propertydata?.owner_phone?.[0]?.phone_category}` : "N/A"}</span>
              </p>
            ) : (
              "N/A"
            )}
          </div>
        </div>
      </div>

      <div className="mt-6 body-N">
        <p className="dark-M">Comments</p>
        <p className="dark-H">{propertydata?.comment ? propertydata?.comment : "N/A"}</p>
      </div>

      <div className="md:flex justify-between body-N">
        <div className="mt-6 md:w-[50%]">
          <p className="dark-M">Property Type</p>
          <p className="tags green-H body-S mt-1">{propertydata?.property_type?.type}</p>
        </div>

        <div className="mt-6 md:w-[50%]">
          <p className="dark-M">Property Tags</p>
          <div className="flex flex-wrap items-center gap-2 mt-1">
            {propertydata?.propertytag_details?.map((el, i) => (
              <p key={i} className="tags green-H body-S">
                {el?.tag_name}
              </p>
            ))}
          </div>
        </div>
      </div>

      <DeleteConfirmationModal showModal={showDeleteModal} disable={disableDelete} onClose={() => setShowDeleteModal(false)} handleDelete={handleDelete} />
    </div>
  );
};

export default PropertyDetailHeader;
