import React, { useEffect, useState } from "react";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import { formatPhoneNumber } from "../../../utils/utils";
import { NotificationManager } from "react-notifications";
import { RiArrowUpSLine as UpArrow } from "react-icons/ri";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { filterThreeTabData } from "../../../utils/options";
import SearchDropdown from "../../../components/Dropdowns/SearchDropdown";
import FilterTabData from "../../../components/FilterComponents/FilterTabData";
import OptionalOption from "../../../components/FilterComponents/OptionalOption";
import FilterFieldHeader from "../../../components/FilterComponents/FilterFieldHeader";
import FilterSearchSection from "../../../components/FilterComponents/FilterSearchSection";

const tag = [
  { label: "Do Not Send", value: "do not send" },
  { label: "Do Not Blast", value: "do not blast" },
  { label: "Bad #", value: "bad" },
];

const CompanyFilterData = ({ filterData, handleFilterChange, onSetFilterData, isSidebarOpen }) => {
  const [config] = useAuth();
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [companyTagOptions, setCompanyTagOptions] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const newTags = checked ? [...filterData.tags, value] : filterData.tags.filter((tag) => tag !== value);
    handleFilterChange(newTags, "tags");
  };

  const fetchCompanyTags = () => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=company_tags`, config)
      .then((res) => {
        const value = res?.data?.data?.company_tags;
        setCompanyTagOptions(
          value?.map((el) => ({
            value: el?.tag_name?.toLowerCase(),
            label: el?.tag_name?.toLowerCase(),
          }))
        );
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    if (isSidebarOpen) {
      fetchCompanyTags();
    }
  }, [isSidebarOpen]);

  const fetchPropertySuggestions = (inputValue) => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=properties&search=${inputValue}`, config)
      .then((res) => {
        const data = res.data.data.properties;
        const options = data.map((el) => ({ value: el.id, label: el?.property_name, desc: `${el.street_address}, ${el.city}, ${el.state}` }));
        setPropertyOptions(options);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const lastupdatehandler = () => {
    onSetFilterData({ ...filterData, last_contact: "date", lastContactSentDate: "" });
  };

  return (
    <div className="">
      <FilterSearchSection
        type="text"
        title={"Company Name"}
        placeholder={"write here"}
        value={filterData?.company_name}
        onChange={(e) => {
          handleFilterChange(e.target.value, "company_name");
        }}
      />

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <FilterSearchSection
        type="text"
        title={"Primary Phone"}
        placeholder={"write phone"}
        value={formatPhoneNumber(filterData?.primaryphone)}
        onChange={(e) => {
          const value = e.target.value;
          const cleaned = ("" + value)?.replace(/\D/g, "")?.slice(0, 10);
          handleFilterChange(cleaned, "primaryphone");
        }}
      />

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
        <FilterFieldHeader
          title={"Address"}
          data={filterData?.streetaddress}
          type="string"
          handleCross={() => {
            handleFilterChange("", "streetaddress");
          }}
        />

        <div className="search-box w-[100%] mt-2">
          <input
            type="text"
            className="body-N"
            placeholder="write street address here"
            style={{ width: "100%" }}
            value={filterData?.streetaddress}
            onChange={(e) => {
              handleFilterChange(e.target.value, "streetaddress");
            }}
          />
        </div>

        <div className=" flex flex-row gap-4 mt-2">
          <div className="search-box w-[30%]">
            <input
              type="text"
              className="body-N"
              placeholder="city"
              style={{ width: "100%" }}
              value={filterData?.city}
              onChange={(e) => {
                handleFilterChange(e.target.value, "city");
              }}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div className="search-box w-[30%]">
            <input
              type="text"
              className="body-N"
              placeholder="state"
              style={{ width: "100%" }}
              value={filterData?.state}
              onChange={(e) => {
                handleFilterChange(e.target.value, "state");
              }}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div className="search-box w-[30%]">
            <input
              type="number"
              className="body-N"
              placeholder="zip code"
              style={{ width: "100%" }}
              value={filterData?.zip}
              onChange={(e) => {
                handleFilterChange(e.target.value, "zip");
              }}
              onWheel={(e) => e.target.blur()}
            />
          </div>
        </div>
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
        <div className="flex gap-5 justify-between items-center">
          <p className="head-4 dark-H">Tax Record Letter Sent</p>

          <RadioGroup
            onChange={(value) => {
              handleFilterChange(value, "taxrecord");
            }}
            value={filterData?.taxrecord}
          >
            <Stack direction="row" gap={5}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Stack>
          </RadioGroup>
        </div>

        {filterData?.taxrecord === "yes" && (
          <OptionalOption
            activeTab="date"
            onSetLastDate={(value) => {
              handleFilterChange(value, "taxrecordsentdate");
            }}
            date={filterData?.taxrecordsentdate}
            onSetDateRange1={(value) => handleFilterChange(value, "taxrecordsentSdate")}
            date2={filterData?.taxrecordsentSdate}
            onSetDateRange2={(value) => handleFilterChange(value, "taxrecordsentEdate")}
            date3={filterData?.taxrecordsentEdate}
            onClear={() => {
              onSetFilterData({ ...filterData, taxrecordsentdate: "", taxrecordsentSdate: "", taxrecordsentEdate: "" });
            }}
          />
        )}
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
        <FilterTabData
          title={"Last Contacted"}
          tabData={filterThreeTabData}
          activeTab={filterData?.last_contact}
          lastDate={filterData?.lastContactSentDate}
          handleLastUpdate={lastupdatehandler}
          onSetActiveTab={(value) => {
            onSetFilterData({ ...filterData, last_contact: value, lastContactSentDate: "", lastContactSentSdate: "", lastContactSentEdate: "", date_category: "days", within_last_day: "", longer_than_day: "" });
          }}
        />
        <OptionalOption
          activeTab={filterData?.last_contact}
          onSetLastDate={(value) => {
            handleFilterChange(value, "lastContactSentDate");
          }}
          date={filterData?.lastContactSentDate}
          onSetDateRange1={(value) => handleFilterChange(value, "lastContactSentSdate")}
          date2={filterData?.lastContactSentSdate}
          onSetDateRange2={(value) => handleFilterChange(value, "lastContactSentEdate")}
          date3={filterData?.lastContactSentEdate}
          category={filterData?.date_category}
          onSetCategory={(value) => handleFilterChange(value, "date_category")}
          lastUp_days={filterData?.last_contact === "within_last" ? filterData?.within_last_day : filterData?.last_contact === "longer_than" ? filterData?.longer_than_day : ""}
          onSetDays={(value) => {
            if (filterData?.last_contact === "within_last") {
              onSetFilterData({ ...filterData, within_last_day: value, longer_than_day: "" });
            } else if (filterData?.last_contact === "longer_than") {
              onSetFilterData({ ...filterData, within_last_day: "", longer_than_day: value });
            } else {
              onSetFilterData({ ...filterData, within_last_day: "", longer_than_day: "" });
            }
          }}
          onClear={() => {
            onSetFilterData({ ...filterData, lastContactSentDate: "", lastContactSentSdate: "", lastContactSentEdate: "" });
          }}
        />
        <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />
      </div>

      <div className="mt-4">
        <label className="dark-H head-4 mb-4">Property Name</label>
        <div className="mt-2">
          <input
            type="text"
            className="body-N"
            placeholder="write here"
            style={{ width: "100%" }}
            value={filterData?.property}
            onChange={(e) => {
              handleFilterChange(e.target.value, "property");
            }}
          />
        </div>

        <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />
      </div>

      <div className="">
        <p className="head-4 dark-H">Own "X" or "X-Y" Amount of Properties</p>
        <OptionalOption
          activeTab={"date"}
          type="number"
          title="Range"
          placeholder="enter here"
          onSetLastDate={(value) => {
            handleFilterChange(value, "count");
          }}
          date={filterData?.count}
          onSetDateRange1={(value) => handleFilterChange(value, "start_count")}
          date2={filterData?.start_count}
          onSetDateRange2={(value) => handleFilterChange(value, "end_count")}
          date3={filterData?.end_count}
          onClear={() => {
            onSetFilterData({ ...filterData, count: "", start_count: "", end_count: "" });
          }}
        />
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div className="flex gap-5 justify-between items-center">
        <p className="head-4 dark-H">Website</p>
        <RadioGroup
          onChange={(value) => {
            handleFilterChange(value, "website");
          }}
          value={filterData?.website}
        >
          <Stack direction="row" gap={5}>
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Stack>
        </RadioGroup>
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
        <FilterFieldHeader
          title={"Tags"}
          data={filterData?.tags?.length}
          type="array"
          handleCross={() => {
            handleFilterChange([], "tags");
          }}
        />

        <div className="mt-4">
          {companyTagOptions.flatMap((el, idx) => (
            <label key={idx} className="container">
              <input type="checkbox" value={el.value} onChange={handleCheckboxChange} checked={filterData.tags.includes(el.value)} />
              <span className="checkmark"></span>
              <p className="tags green-H body-S">{el.label}</p>
            </label>
          ))}
        </div>
      </div>

      {/* <div className=" mt-8">
        <FilterFieldHeader
          title={"Tags"}
          data={filterData?.tags?.length}
          type="array"
          handleCross={() => {
            handleFilterChange([], "tags");
          }}
        />

        <div className="mt-6">
          {tag.flatMap((el, idx) => (
            <label key={idx} className="container">
              <input type="checkbox" value={el.value} onChange={handleCheckboxChange} checked={filterData.tags.includes(el.value)} />
              <span className="checkmark"></span>
              <p className="tags green-H body-S">{el.label}</p>
            </label>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default CompanyFilterData;
