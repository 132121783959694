import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../utils/Element";
import Modal from "../../../components/Modal/Modal";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import SearchDropdown from "../../../components/Dropdowns/SearchDropdown";
import Select from 'react-select';
import { dropdownOptions } from "../../../utils/options";

const CreateSmartPlanModal = ({ showModal, onClose, from, id   }) => {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [smartPlanId, setSmartPlanId] = useState("");
  const [error, setError] = useState();
  const [disable, setDisable] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [options, setOptions] = useState([]);
  const [contactOptions, setContactData] = useState([]);
  const [value, setValue] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [adminOptions, setAdminOptions] = useState([]);
  const [salesOptions, setSalesOptions] = useState([]);
  const selectRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [companyContactId, setCompanyContactId] = useState();
  const [startDate, setStartDate] = useState({
    type: "today",
    selectedDate: "",
  });
  const [formData, setFormData] = useState({
    admin: "", 
    sales: "", 
  });
 

  const handleClose = (success) => {
    setSelectedOptions([]);
    setName("");
    setFormData({ admin: "", sales: "" });
    setError();
    onClose(success);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSend = {
      name: name,
      admin: formData.admin, 
      sales: formData.sales,
    };

    if(from === "contact" || from === "company"){
      if(from === "contact"){
        const updatedContactIds = Array.isArray(selectedContacts) && selectedContacts.length > 0 
        ? [...selectedContacts, parseInt(id, 10)] 
        : [parseInt(id, 10)];

        Object.assign(dataToSend, { contact: updatedContactIds.filter(id => id !== null),
          start_date: startDate.type === "today" ? moment().format("YYYY-MM-DD") : startDate.selectedDate , 
          accountType: value});
      }
      if(from === "company"){
        const updatedCompanyContactIds = Array.isArray(selectedContacts) && selectedContacts.length > 0 
        ? [...selectedContacts, parseInt(companyContactId.contactId, 10)] 
        : [parseInt(companyContactId.contactId, 10)];

        Object.assign(dataToSend, { contact: updatedCompanyContactIds.filter(id => id !== null),
          start_date: startDate.type === "today" ? moment().format("YYYY-MM-DD") : startDate.selectedDate , 
          accountType: value});
      }
   
    }

    const url = from === "contact" ? `update-smartplan/${smartPlanId}?contact_id=${id}` : from === "company" ? `update-smartplan/${smartPlanId}?company_id=${id}` : "create-smartplan";
    setDisable(true);
    axios
      .post(`${BASE_URL}/${url}`,  dataToSend, config)
      .then((res) => {
        const id = res.data?.smartplan?.id;
        if (from !== "contact" && from !== "company") {
          navigate(`/touch-plan/${id}`);
        }
        handleClose(true);
        fetchSmartPlans();
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
        setValue(""); 
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An unexpected error occurred";
      
        if (errorMessage.includes("Touchplan could not be found")) {
          setError("Touchplan could not be found");
          NotificationManager.error("Touchplan could not be found");
        } else {
          setError(errorMessage); 
        }
      })
       .finally(() => setDisable(true));
  };

  const fetchSmartPlans = (searchTerm = '') => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=smartplan&search=${encodeURIComponent(searchTerm)}`, config)
      .then((res) => {
        const smartplans = res?.data?.data?.smart_plan;
        const filteredSmartPlans = smartplans?.map((el) => ({
          value: el.id,
          label: el.name,
          contactArray: el.contact,
        }));

        if (from === "contact" || from === "company") {
          setOptions(filteredSmartPlans);
        } else {
          setOptions([]); 
        }
      })
      .catch((err) => {
        setError(err.response?.data?.message || 'Failed to fetch data');
        setOptions([]); 
      });
  };

  useEffect(() => {
    fetchSmartPlans(); 
  }, []); 
  
  const handleSelectSmartplan = (option) => {
    setName(option.label); 
    setSmartPlanId(option.value);
    setSelectedContacts(option.contactArray);
    setOptions([]); 
  };

  const fetchContactSuggestions = (inputValue) => {
    if (inputValue !== "") {
      const url = from === "company" ? `get-datalisting?list=contacts&company_id=${id}&search=${inputValue}` : `get-datalisting?list=contacts&search=${inputValue}`;
      axios
        .get(`${BASE_URL}/${url}`, config)
        .then((res) => {
          const value = res?.data?.data?.contact_list;
          setContactData(
            value?.map((data) => ({
              value: data?.id,
              label: `${data?.first_name} ${data?.last_name}`,
            }))
          );
        })
        .catch((err) => {
          if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
            NotificationManager.error(err.response?.data?.message);
          }
        });
    } else {
      setContactData([]);
    }
  };


  const fetchAdminData = () => {
    axios.get(`${BASE_URL}/get-datalisting?list=users&role_id=2`, config)
    .then((res) => {
      const data = res?.data?.data;
      const options = data?.map((el) => ({ value: el?.id, label: `${el?.first_name} ${el?.last_name}`, role: el?.role_id }));
      setAdminOptions(options);
    })
    .catch((err) => {
      if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
        NotificationManager.error(err.response?.data?.message);
      }
    })
    .finally(() => setLoading(false));
  };
  
  const fetchSalesData = () => {
    axios.get(`${BASE_URL}/get-datalisting?list=users&role_id=3`, config)
    .then((res) => {
      const data = res?.data?.data;
      const options = data?.map((el) => ({ value: el?.id, label: `${el?.first_name} ${el?.last_name}`, role: el?.role_id }));
      setSalesOptions(options);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
    };
  
  

  useEffect(() => {
    fetchAdminData();
    fetchSalesData();
  }, []);


   const handleInputChange = (value, name) => {
    setError((prev) => ({ ...prev, [name]: "" })); 
    setFormData((prev) => ({ ...prev, [name]: value })); 
  };
  
  console.log("formData" , formData)

  return (
    <Modal title={"Create TouchPlan"} desc={"Enter a name for the plan."} show={showModal} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
             <div>
               <label className="dark-H head-4 mb-2">
                 TouchPlan Name<span className="red-D">*</span>
               </label>
               <input
                 className="body-N capitalize"
                 name="name"
                 type="text"
                 autoComplete="off"
                 placeholder="Write name here"
                 value={name}
                 onChange={(e) => {
                   setName(e.target.value);
                   fetchSmartPlans(e.target.value); 
                 }}
               />
               {error?.name && <span className="red-D">{error?.name}</span>}
             
               {name && options.length > 0 && (
                 <div className="z-10 w-full mt-1 light-bg-L border border-gray-300 rounded-md shadow-md max-h-[40vh] overflow-y-auto">
                   {options.map((option) => (
                     <div
                       key={option.value}
                       className="px-3 py-2 body-N hover:bg-gray-100 dark-H"
                       onClick={() => handleSelectSmartplan(option)}
                     >
                       <p>{option.label}</p>
                     </div>
                   ))}
                 </div>
               )}
             </div>



              <div className="mt-4">
              {from === "company" ? (
            <div className="mb-6">
              <p className="head-4 dark-H mb-2">
                Contact Name<span className="red-D">*</span>
              </p>
              <SearchDropdown
                isTop={false}
                placeholder="Search Here..."
                text={companyContactId?.contactName}
                options={contactOptions}
                fetchSuggestions={fetchContactSuggestions}
                onSetOptions={(value) => setContactData(value)}
                handleSelect={(option) => {
                  setError({ ...error, contact_id: "" });
                  setCompanyContactId({ ...companyContactId, contactId: option.value, contactName: option.label });
                }}
              />
              {error?.contact_id && <span className="body-S red-D">{error?.contact_id}</span>}
            </div>
          ) : null}


              </div>


              <div className="mt-4">
              {from === "contact" || from === "company" ? (
                <>
                  <div>
                    <label className="dark-H head-4 mb-2">
                      Admin<span className="red-D">*</span>
                    </label>
                    <Select
                      ref={selectRef}
                      className="body-N"
                      options={adminOptions} 
                      placeholder="Select"
                      value={formData?.admin ? adminOptions.find((option) => option?.value === formData?.admin) : null}
                      onChange={(option) => {
                        handleInputChange(option?.value, "admin");
                        fetchAdminData();
                      }}
                    />
                  </div>
            
                
                  <div className="mt-4">
                    <label className="dark-H head-4 mb-2">
                      Sales<span className="red-D">*</span>
                    </label>
                    <Select
                      ref={selectRef}
                      className="body-N"
                      options={salesOptions} 
                      placeholder="Select"
                      value={formData?.sales ? salesOptions.find((option) => option?.value === formData?.sales) : null}
                      onChange={(option) => {
                        handleInputChange(option?.value, "sales");
                        fetchSalesData();

                      }}
                    />
                  </div>
                </>
              ) : null}
            </div>
            
                     
                     
                         
           <div className="mt-6">
          <button type="submit"  className="save-button light-L head-5 green-bg-H">
            Add TouchPlan
          </button>
          <button type="button" onClick={handleClose} className="green-H ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateSmartPlanModal;
