import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../Loader";
import ActionsMenu from "../ActionsMenu";
import useAuth from "../../hooks/useAuth";
import { BASE_URL } from "../../utils/Element";
import { FiPlus as Plus } from "react-icons/fi";
import swap from "../../assets/svgs/swap-vertical.svg";
import BuyerFilter from "../FilterComponents/BuyerFilter";
import sortAsce from "../../assets/svgs/sort-ascending.svg";
import sortDesc from "../../assets/svgs/sort-descending.svg";
import AddAcquisitionModal from "./Modals/AddAcquisitionModal";
import ContactPagination from "../Pagination/ContactPagination";
import { initialBuyerFilterData } from "../../utils/initialData";
import EditBuyersModal from "../../pages/Buyers/EditBuyersModal";
import BuyerDetailModal from "../../pages/Buyers/BuyerDetailModal";
import DeleteConfirmationModal from "../ConfirmationModals/DeleteConfirmationModal";

const AcquisitionTab = ({ from }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [disableDelete, setDisableDelete] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [acquisitionsData, setAcquisitionsData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const location = useLocation();
  const isSidebarCollapsed = useSelector((state) => state.isSidebarCollapsed);
  const [paginationData, setPaginationData] = useState({ totalItems: 0, from: 0, to: 0, totalPages: 0 });
  const [filterData, setFilterData] = useState(() => {
    const localFilterData = localStorage.getItem("filterData");
    return localFilterData ? JSON.parse(localFilterData) : initialBuyerFilterData;
  });
  const queryParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState(queryParams.get('search') ?? '');
  const [tabSearch, setTabSearch] = useState();

  const buildQueryParams = (filters) => {
    let params = `page=${currentPage}&per_page=${itemPerPage}&list_type=${from}&id=${id}`;

    const { availability_status, buyer_status, state, tenant_name, min_asking_cap_rate, min_price, max_price, landlord_responsibilities, property_type, min_lease_term_reamaining, lease_date, lease_start_date, lease_end_date, lease_date_category, lease_within_last_day, lease_longer_than_day, last_update, last_update_date, last_update_start_date, last_update_end_date, date_category, within_last_day, longer_than_day } = filters;

    if (availability_status) {
      params += `&availability_status=${encodeURIComponent(availability_status)}`;
    }
    if (state?.length > 0) {
      params += `&state=${encodeURIComponent(state)}`;
    }
    if (tenant_name) {
      params += `&tenant_name=${encodeURIComponent(tenant_name)}`;
    }
    if (min_asking_cap_rate) {
      params += `&min_asking_cap_rate=${encodeURIComponent(min_asking_cap_rate)}`;
    }
    if (min_price) {
      params += `&min_price=${encodeURIComponent(min_price)}`;
    }
    if (max_price) {
      params += `&max_price=${encodeURIComponent(max_price)}`;
    }
    if ((min_lease_term_reamaining && lease_date) || (min_lease_term_reamaining && lease_start_date && lease_end_date)) {
      params += `&min_lease_term_reamaining=${encodeURIComponent(min_lease_term_reamaining)}`;
    }
    if (lease_date) {
      params += `&lease_date=${encodeURIComponent(lease_date)}`;
    }
    if (lease_start_date) {
      params += `&lease_start_date=${encodeURIComponent(lease_start_date)}`;
    }
    if (lease_end_date) {
      params += `&lease_end_date=${encodeURIComponent(lease_end_date)}`;
    }
    if (lease_within_last_day) {
      params += `&lease_within_last_day=${encodeURIComponent(lease_within_last_day)}`;
    }
    if (lease_longer_than_day) {
      params += `&lease_longer_than_day=${encodeURIComponent(lease_longer_than_day)}`;
    }
    if ((last_update && last_update_date) || (last_update && last_update_start_date && last_update_end_date)) {
      params += `&last_update=${encodeURIComponent(last_update)}`;
    }
    if (last_update_date) {
      params += `&last_update_date=${encodeURIComponent(last_update_date)}`;
    }
    if (last_update_start_date) {
      params += `&last_update_start_date=${encodeURIComponent(last_update_start_date)}`;
    }
    if (last_update_end_date) {
      params += `&last_update_end_date=${encodeURIComponent(last_update_end_date)}`;
    }
    if (within_last_day) {
      params += `&within_last_day=${encodeURIComponent(within_last_day)}`;
    }
    if (longer_than_day) {
      params += `&longer_than_day=${encodeURIComponent(longer_than_day)}`;
    }
    if (Array.isArray(landlord_responsibilities) && landlord_responsibilities.length > 0) {
      params += `&landlord_responsibilities=${encodeURIComponent(landlord_responsibilities.join(","))}`;
    }
    
    if (Array.isArray(property_type) && property_type.length > 0) {
      params += `&property_type=${encodeURIComponent(property_type.join(","))}`;
    }
    
    if (sortDirection && sortBy !== "id") {
      params += `&sort_direction=${sortDirection}`;
    }
    if (sortBy !== "id") {
      params += `&sort_by=${sortBy}`;
    }
    if (tabSearch) {
      params += `&search_number=${tabSearch}`;
    }

    return params;
  };

  const fetchAcquisitions = (filters) => {
    const queryParams = buildQueryParams(filters);

    axios
      .get(`${BASE_URL}/acquisition-list/?${queryParams}`, config)
      .then((res) => {
        const value = res?.data?.data?.acquisitions;
        setAcquisitionsData(value?.data || []);
        setPaginationData({
          totalItems: value?.total,
          from: value?.from,
          to: value?.to,
          totalPages: value?.last_page,
        });
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAcquisitions(filterData);
  }, [sortDirection, sortBy , tabSearch]);

  useEffect(() => {
    onSuccess();
  }, [currentPage, itemPerPage, id, from]);

  const onSuccess = (filters) => {
    setLoading(true);
    if (filters) {
      fetchAcquisitions(filters);
    } else {
      fetchAcquisitions(filterData);
    }
  };

  const handleDelete = () => {
    setDisableDelete(true);
    axios
      .delete(`${BASE_URL}/delete-acquisition/${selectedId}`, config)
      .then((res) => {
        onSuccess();
        setSelectedId("");
        setShowDeleteModal(false);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setDisableDelete(false));
  };

  const handleNext = () => {
    if (currentPage !== paginationData.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams) {
      queryParams.set('search', search);
    } else {
      queryParams.delete('search'); 
    }
    navigate({ search: queryParams.toString() }, { replace: true });
  }, [search, navigate, location.search]);

  return (
    <div>
      <div className="flex justify-between items-start head-5 green-H">
             <div className="search-box contacts">
              <input
                type="text"
                className="body-S"
                placeholder="Search Here.."
                value={tabSearch}
                onChange={(e) => {
                  setTabSearch(e.target.value);
                }}
                style={{ border: "1px solid #D8D8D8", marginTop: "0px" }}
              />
            </div>

        <p className="head-5 green-H flex items-center gap-1" role="button" onClick={() => setShowModal(true)}>
          <Plus /> Add Acquisition Criteria
        </p>
      </div>

      <div className="light-bg-L pb-3 table-container">
        <div className={`${isSidebarCollapsed ? "tab-collapsed-width" : "tab-width"} table-info light-bg-L`}>
          {loading ? (
            <Loader />
          ) : acquisitionsData?.length === 0 ? (
            <p className="body-N text-center">No Acquisition Criteria Available</p>
          ) : (
            <table className="contact-table light-bg-L">
              <thead>
                <tr className="uppercase body-N dark-M">
                  <th className="green-H">
                    <div className="table-header">
                      Property Type
                      <img
                        role="button"
                        src={sortBy !== "property_type" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("property_type");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      MIN. ASKING PRICE
                      <img
                        role="button"
                        src={sortBy !== "min_price" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("min_price");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Max ASKING PRICE
                      <img
                        role="button"
                        src={sortBy !== "max_price" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("max_price");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      MIN. ASKING Cap Rate
                      <img
                        role="button"
                        src={sortBy !== "min_asking_cap_rate" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("min_asking_cap_rate");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      MIN. Lease term remaining
                      <img
                        role="button"
                        src={sortBy !== "min_lease_term_reamaining" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("min_lease_term_reamaining");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                
               {acquisitionsData.flatMap((el, idx) => (
                       <tr
                        key={idx}
                        className="body-N dark-H"
                        role="button"
                        onClick={() => {
                          navigate(`/buyer/${el?.id}`);
                        }}
                      >
                       <td className="flex flex-wrap items-center gap-2" style={{ maxWidth: "250px" }}>
                         <Link
                           to={`/buyer/${el?.id}`}
                           style={{ textDecoration: 'none', color: 'inherit' }} 
                         >
                           {el?.property_type?.map((tag, i) => (
                             <p key={i} className="tags green-H body-S">
                               {tag?.type}
                             </p>
                           ))}
                         </Link>
                       </td>
                       <td className="text-center">
                         <Link
                           to={`/buyer/${el?.id}`}
                           style={{ textDecoration: 'none', color: 'inherit' }}
                         >
                           {el?.min_price ? `$ ${el?.min_price?.toLocaleString()}` : ""}
                         </Link>
                       </td>
                       <td className="text-center">
                         <Link
                           to={`/buyer/${el?.id}`}
                           style={{ textDecoration: 'none', color: 'inherit' }}
                         >
                           {el?.max_price ? `$ ${el?.max_price?.toLocaleString()}` : ""}
                         </Link>
                       </td>
                       <td className="text-center">
                         <Link
                           to={`/buyer/${el?.id}`}
                           style={{ textDecoration: 'none', color: 'inherit' }}
                         >
                           {el?.min_asking_cap_rate ? `${el?.min_asking_cap_rate} %` : ""}
                         </Link>
                       </td>
                       <td className="text-center">
                         <Link
                           to={`/buyer/${el?.id}`}
                           style={{ textDecoration: 'none', color: 'inherit' }}
                         >
                           {el?.min_lease_term_reamaining}
                         </Link>
                       </td>
                       <td>
                         <ActionsMenu
                           handleEdit={() => {
                             setShowEditModal(true);
                             setSelectedId(el?.id);
                           }}
                           handleDelete={() => {
                             setShowDeleteModal(true);
                             setSelectedId(el?.id);
                           }}
                         />
                       </td>
                     </tr>
                   ))}

              </tbody>
            </table>
          )}
        </div>
        {acquisitionsData?.length !== 0 && (
          <ContactPagination
            paginationData={paginationData}
            handleNext={handleNext}
            handlePrev={handlePrev}
            onSuccess={onSuccess}
            itemPerPage={itemPerPage}
            handleItemPerPageSelect={(value) => {
              setItemPerPage(value);
              setCurrentPage(1);
            }}
          />
        )}
      </div>

      <AddAcquisitionModal showModal={showModal} onClose={() => setShowModal(false)} from={from} fetchAcquisitions={onSuccess} />

      <BuyerDetailModal showModal={showDetailModal} onClose={() => setShowDetailModal(false)} id={selectedId} />

      <EditBuyersModal showModal={showEditModal} onClose={() => setShowEditModal(false)} onCallApi={onSuccess} id={selectedId} />

      <DeleteConfirmationModal showModal={showDeleteModal} disable={disableDelete} onClose={() => setShowDeleteModal(false)} handleDelete={handleDelete} />
    </div>
  );
};

export default AcquisitionTab;
