import React from "react";
import Tabs from "../Tabs/Tabs";
import FilterFieldHeader from "./FilterFieldHeader";


const FilterTabData = ({ title, tabData, activeTab, onSetActiveTab, lastDate, handleLastUpdate }) => {
  return (
    <div>
      <FilterFieldHeader title={title} type="to" symbol={"  to  "} data={activeTab} data2={lastDate} handleCross={handleLastUpdate} />

      <div className="mt-6">
        <Tabs tabData={tabData} activeTab={activeTab} onSetActiveTab={onSetActiveTab} />
      </div>

    </div>
  );
};

export default FilterTabData;
