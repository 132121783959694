import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "./Modal/Modal";
import useAuth from "../hooks/useAuth";
import { BASE_URL } from "../utils/Element";
import { NotificationManager } from "react-notifications";
import { FaPhoneVolume as PhoneIcon } from "react-icons/fa6";

const PhoneStatusModal = ({ phone, showModal, onClose }) => {
  const [config] = useAuth();
  const [ringId, setRingId] = useState("");
  const [status, setStatus] = useState("progress");

  const handleClose = () => {
    setStatus("progress");
    setRingId("");
    onClose();
  };

  const handlePhone = () => {
    axios
      .get(`${BASE_URL}/ringcentral/testingPhoneCall?from_phone_number=+12678284937&to_phone_number=+1${phone}`, config)
      .then((res) => {
        setRingId(res?.data?.id);
        handlePhoneStatus(res?.data?.id);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const handlePhoneStatus = (id) => {
    axios
      .get(`${BASE_URL}/ringcentral/makeCall?ring_out_status_id=${id}`, config)
      .then((res) => {
        setStatus(res?.data?.status?.callStatus);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const handlePhoneDisconnect = () => {
    axios
      .get(`${BASE_URL}/ringcentral/make-callcut?ring_out_status_id=${ringId}`, config)
      .then((res) => {
        setStatus(res?.data?.status?.callStatus);
        handleClose();
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    if (showModal) {
      handlePhone();
    }
  }, [showModal]);

  return (
    <Modal show={showModal} onClose={handlePhoneDisconnect} title={"Phone Connect Status"}>
      <div className="flex flex-col justify-center items-center gap-5">
        <PhoneIcon size={50} className="green-H" />
        <p className="body-N dark-H">{status === "progress" ? "Call in progress..." : "Connected Succcessfully!"}</p>
      </div>
    </Modal>
  );
};

export default PhoneStatusModal;
