import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import Modal from "../Modal/Modal";
import useAuth from "../../hooks/useAuth";
import { BASE_URL } from "../../utils/Element";
import { NotificationManager } from "react-notifications";
import { AiOutlineClose as CrossIcon } from "react-icons/ai";

const SaveSearchModal = ({ showModal, onClose, from,initialFilterData, saveSearchFilterData, onSetSelectedSaveFilterId }) => {
  const [config] = useAuth();
  const [name, setName] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const handleClose = () => {
    onClose();
    setName("");
    setError();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSend = {
      name: name,
      type: from,
      filters: saveSearchFilterData || initialFilterData,
    };

    axios
      .post(`${BASE_URL}/save-search`, dataToSend, config)
      .then((res) => {
        setName("");
        setError();
        fetchSavedSearch();
        onSetSelectedSaveFilterId(res?.data?.search?.id);
        handleClose();
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.errors);
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const handleReplace = (e) => {
    e.preventDefault();
    const dataToSend = {
      name: name,
      type: from,
      is_replace: true,
      filters: saveSearchFilterData,
    };
    
    axios
      .put(`${BASE_URL}/replace-save-search`, dataToSend, config)
      .then((res) => {
        setName("");
        setError();
        fetchSavedSearch();
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.errors);
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const fetchSavedSearch = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/list-search?type=${from}`, config)
      .then((res) => {
        setSearchData(res?.data?.search);
        setName("");
        setError();
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = (id) => {
    axios
      .delete(`${BASE_URL}/delete-search/${id}`, config)
      .then((res) => {
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
        fetchSavedSearch();
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  return (
    <Modal show={showModal} onClose={handleClose} title="Save Filter as" desc="Save your filter criteria to quickly access and reuse them in the future.">
      <div className="min-h-[80px]">
        {loading ? (
          <Loader />
        ) : (
          <div>
            <form>
              <div>
                <input
                  className="body-N capitalize"
                  name="name"
                  type="text"
                  placeholder="write name here..."
                  value={name}
                  onChange={(e) => {
                    setError({ ...error, name: "" });
                    setName(e.target.value);
                  }}
                />
                {error?.name && <span className="body-S red-D">{error?.name === "The name has already been taken." ? "This name is already exist, did you want to replace it?" : error.name}</span>}
              </div>

              {error?.name === "The name has already been taken." ? (
                <button type="button" onClick={handleReplace} className="head-5 light-L green-bg-H save-button mt-4 w-full">
                  Replace this saved filter
                </button>
              ) : (
                <button type="button" onClick={handleSubmit} className="head-5 light-L green-bg-H save-button mt-4 w-full">
                  Save Filter
                </button>
              )}
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SaveSearchModal;
